export const GET_ALL_SOCIAL = 'GET_ALL_SOCIAL';
export const GET_ALL_SOCIAL_SUCCESS = 'GET_ALL_SOCIAL_SUCCESS';
export const GET_ALL_SOCIAL_ERROR = 'GET_ALL_SOCIAL_ERROR';
export const CREATE_SOCIAL = 'CREATE_SOCIAL';
export const CREATE_SOCIAL_SUCCESS = 'CREATE_SOCIAL_SUCCESS';
export const CREATE_SOCIAL_ERROR = 'CREATE_SOCIAL_ERROR';
export const UPDATE_SOCIAL = 'UPDATE_SOCIAL';
export const UPDATE_SOCIAL_SUCCESS = 'UPDATE_SOCIAL_SUCCESS';
export const UPDATE_SOCIAL_ERROR = 'UPDATE_SOCIAL_ERROR';
export const DELETE_SOCIAL = 'DELETE_SOCIAL';
export const DELETE_SOCIAL_SUCCESS = 'DELETE_SOCIAL_SUCCESS';
export const DELETE_SOCIAL_ERROR = 'DELETE_SOCIAL_ERROR';
