export const GET_ALL_LANGUAGE = 'GET_ALL_LANGUAGE';
export const GET_ALL_LANGUAGE_SUCCESS = 'GET_ALL_LANGUAGE_SUCCESS';
export const GET_ALL_LANGUAGE_ERROR = 'GET_ALL_LANGUAGE_ERROR';
export const GET_SINGLE_LANGUAGE = 'GET_SINGLE_LANGUAGE';
export const GET_SINGLE_LANGUAGE_SUCCESS = 'GET_SINGLE_LANGUAGE_SUCCESS';
export const GET_SINGLE_LANGUAGE_ERROR = 'GET_SINGLE_LANGUAGE_ERROR';
export const CREATE_LANGUAGE = 'CREATE_LANGUAGE';
export const CREATE_LANGUAGE_SUCCESS = 'CREATE_LANGUAGE_SUCCESS';
export const CREATE_LANGUAGE_ERROR = 'CREATE_LANGUAGE_ERROR';
export const UPDATE_LANGUAGE = 'UPDATE_LANGUAGE';
export const UPDATE_LANGUAGE_SUCCESS = 'UPDATE_LANGUAGE_SUCCESS';
export const UPDATE_LANGUAGE_ERROR = 'UPDATE_LANGUAGE_ERROR';
export const DELETE_LANGUAGE = 'DELETE_LANGUAGE';
export const DELETE_LANGUAGE_SUCCESS = 'DELETE_LANGUAGE_SUCCESS';
export const DELETE_LANGUAGE_ERROR = 'DELETE_LANGUAGE_ERROR';

