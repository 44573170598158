/* eslint-disable import/no-cycle */
/* SETTINGS */
export const CHANGE_LOCALE = 'CHANGE_LOCALE';

/* MENU */
export const MENU_SET_CLASSNAMES = 'MENU_SET_CLASSNAMES';
export const MENU_CONTAINER_ADD_CLASSNAME = 'MENU_CONTAINER_ADD_CLASSNAME';
export const MENU_CLICK_MOBILE_MENU = 'MENU_CLICK_MOBILE_MENU';
export const MENU_CHANGE_DEFAULT_CLASSES = 'MENU_CHANGE_DEFAULT_CLASSES';
export const MENU_CHANGE_HAS_SUB_ITEM_STATUS =
  'MENU_CHANGE_HAS_SUB_ITEM_STATUS';

export * from './admin/actions';
export * from './article/actions';
export * from './auth/actions';
export * from './community/acticle/actions';
export * from './community/all/actions';
export * from './community/contributorRole/actions';
export * from './community/event/actions';
export * from './community/group/actions';
export * from './community/groupPrivacyOption/actions';
export * from './community/member/actions';
export * from './community/memberRole/actions';
export * from './community/post/actions';
export * from './community/topic/actions';
export * from './community/topicType/actions';
export * from './contest/contestEntry/actions';
export * from './contest/contestList/actions';
export * from './contest/contestMember/actions';
export * from './contest/contestTemplate/actions';
export * from './expert_marketplace/actions';
export * from './expert_marketplace/expertiseReview/actions';
export * from './hubbers-team/actions';
export * from './investor/investorTransaction/actions';
export * from './investor/worldwideShare/actions';
export * from './investor/zone/actions';
export * from './job/job/actions';
export * from './job/jobReview/actions';
export * from './kpi/actions';
export * from './managements/ai_prompt/actions';
export * from './managements/ai_prompt_type/actions';
export * from './managements/notification/actions';
export * from './managements/permission/actions';
export * from './managements/walkthrough_category/actions';
export * from './managements/walkthrough_step/actions';
export * from './masterclass/actions';
export * from './masterclass/masterclassReview/actions';
export * from './menu/actions';
export * from './module/moduleType/actions';
export * from './notification/actions';
export * from './options/badge/actions';
export * from './options/basic-type-category/actions';
export * from './options/basic-type/actions';
export * from './options/contest-category-criteria/actions';
export * from './options/contest-category/actions';
export * from './options/country/actions';
export * from './options/course-category/actions';
export * from './options/currency/actions';
export * from './options/expertise-category/actions';
export * from './options/goal/actions';
export * from './options/language-level/actions';
export * from './options/language/actions';
export * from './options/marketplace_category/actions';
export * from './options/nationality/actions';
export * from './options/product-category/actions';
export * from './options/product-subcategory/actions';
export * from './options/product-tags/actions';
export * from './options/product_innovation_category/actions';
export * from './options/product_subcategory_faq/actions';
export * from './options/product_tech_category/actions';
export * from './options/skill/actions';
export * from './options/timezone/actions';
export * from './options/translation-language/actions';
export * from './options/workspace_category/actions';
export * from './options/workspace_category_card/actions';
export * from './partner/partner-contact/actions';
export * from './partner/partner-type/actions';
export * from './partner/partner/actions';
export * from './product_launcher/actions';
export * from './push_notification/actions';
export * from './settings/actions';
export * from './social/social/actions';
export * from './team/all-teams/actions';
export * from './team/team-member-role/actions';
export * from './team/team-member/actions';
export * from './translation/translationKey/actions';
export * from './translation/translationLanguage/actions';
export * from './translation/translationProject/actions';
export * from './user-role/actions';
export * from './user/actions';
