export const GET_ALL_PARTNER_CONTACT = 'GET_ALL_PARTNER_CONTACT';
export const GET_ALL_PARTNER_CONTACT_SUCCESS =
  'GET_ALL_PARTNER_CONTACT_SUCCESS';
export const GET_ALL_PARTNER_CONTACT_ERROR = 'GET_ALL_PARTNER_CONTACT_ERROR';
export const CREATE_PARTNER_CONTACT = 'CREATE_PARTNER_CONTACT';
export const CREATE_PARTNER_CONTACT_SUCCESS = 'CREATE_PARTNER_CONTACT_SUCCESS';
export const CREATE_PARTNER_CONTACT_ERROR = 'CREATE_PARTNER_CONTACT_ERROR';
export const UPDATE_PARTNER_CONTACT = 'UPDATE_PARTNER_CONTACT';
export const UPDATE_PARTNER_CONTACT_SUCCESS = 'UPDATE_PARTNER_CONTACT_SUCCESS';
export const UPDATE_PARTNER_CONTACT_ERROR = 'UPDATE_PARTNER_CONTACT_ERROR';
export const DELETE_PARTNER_CONTACT = 'DELETE_PARTNER_CONTACT';
export const DELETE_PARTNER_CONTACT_SUCCESS = 'DELETE_PARTNER_CONTACT_SUCCESS';
export const DELETE_PARTNER_CONTACT_ERROR = 'DELETE_PARTNER_CONTACT_ERROR';
