export const GET_ALL_GROUP_PRIVACY_OPTION = 'GET_ALL_GROUP_PRIVACY_OPTION';
export const GET_ALL_GROUP_PRIVACY_OPTION_SUCCESS =
  'GET_ALL_GROUP_PRIVACY_OPTION_SUCCESS';
export const GET_ALL_GROUP_PRIVACY_OPTION_ERROR =
  'GET_ALL_GROUP_PRIVACY_OPTION_ERROR';
export const GET_ALL_PUBLIC_GROUP_PRIVACY_OPTION =
  'GET_ALL_PUBLIC_GROUP_PRIVACY_OPTION';
export const GET_ALL_PUBLIC_GROUP_PRIVACY_OPTION_SUCCESS =
  'GET_ALL_PUBLIC_GROUP_PRIVACY_OPTION_SUCCESS';
export const GET_ALL_PUBLIC_GROUP_PRIVACY_OPTION_ERROR =
  'GET_ALL_PUBLIC_GROUP_PRIVACY_OPTION_ERROR';
export const CREATE_GROUP_PRIVACY_OPTION = 'CREATE_GROUP_PRIVACY_OPTION';
export const CREATE_GROUP_PRIVACY_OPTION_SUCCESS =
  'CREATE_GROUP_PRIVACY_OPTION_SUCCESS';
export const CREATE_GROUP_PRIVACY_OPTION_ERROR =
  'CREATE_GROUP_PRIVACY_OPTION_ERROR';
export const UPDATE_GROUP_PRIVACY_OPTION = 'UPDATE_GROUP_PRIVACY_OPTION';
export const UPDATE_GROUP_PRIVACY_OPTION_SUCCESS =
  'UPDATE_GROUP_PRIVACY_OPTION_SUCCESS';
export const UPDATE_GROUP_PRIVACY_OPTION_ERROR =
  'UPDATE_GROUP_PRIVACY_OPTION_ERROR';
export const DELETE_GROUP_PRIVACY_OPTION = 'DELETE_GROUP_PRIVACY_OPTION';
export const DELETE_GROUP_PRIVACY_OPTION_SUCCESS =
  'DELETE_GROUP_PRIVACY_OPTION_SUCCESS';
export const DELETE_GROUP_PRIVACY_OPTION_ERROR =
  'DELETE_GROUP_PRIVACY_OPTION_ERROR';
