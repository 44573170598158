export const GET_ALL_MEMBER_ROLE = 'GET_ALL_MEMBER_ROLE';
export const GET_ALL_MEMBER_ROLE_SUCCESS = 'GET_ALL_MEMBER_ROLE_SUCCESS';
export const GET_ALL_MEMBER_ROLE_ERROR = 'GET_ALL_MEMBER_ROLE_ERROR';
export const GET_SINGLE_MEMBER_ROLE = 'GET_SINGLE_MEMBER_ROLE';
export const GET_SINGLE_MEMBER_ROLE_SUCCESS = 'GET_SINGLE_MEMBER_ROLE_SUCCESS';
export const GET_SINGLE_MEMBER_ROLE_ERROR = 'GET_SINGLE_MEMBER_ROLE_ERROR';
export const CREATE_MEMBER_ROLE = 'CREATE_MEMBER_ROLE';
export const CREATE_MEMBER_ROLE_SUCCESS = 'CREATE_MEMBER_ROLE_SUCCESS';
export const CREATE_MEMBER_ROLE_ERROR = 'CREATE_MEMBER_ROLE_ERROR';
export const UPDATE_MEMBER_ROLE = 'UPDATE_MEMBER_ROLE';
export const UPDATE_MEMBER_ROLE_SUCCESS = 'UPDATE_MEMBER_ROLE_SUCCESS';
export const UPDATE_MEMBER_ROLE_ERROR = 'UPDATE_MEMBER_ROLE_ERROR';
