export const CREATE_BASIC_TYPE_CATEGORY = 'CREATE_BASIC_TYPE_CATEGORY';
export const CREATE_BASIC_TYPE_CATEGORY_SUCCESS =
  'CREATE_BASIC_TYPE_CATEGORY_SUCCESS';
export const CREATE_BASIC_TYPE_CATEGORY_ERROR =
  'CREATE_BASIC_TYPE_CATEGORY_ERROR';

export const GET_ALL_BASIC_TYPE_CATEGORY = 'GET_ALL_BASIC_TYPE_CATEGORY';
export const GET_ALL_BASIC_TYPE_CATEGORY_SUCCESS =
  'GET_ALL_BASIC_TYPE_CATEGORY_SUCCESS';
export const GET_ALL_BASIC_TYPE_CATEGORY_ERROR =
  'GET_ALL_BASIC_TYPE_CATEGORY_ERROR';

export const GET_BASIC_TYPE_CATEGORY = 'GET_BASIC_TYPE_CATEGORY';

export const UPDATE_BASIC_TYPE_CATEGORY = 'UPDATE_BASIC_TYPE_CATEGORY';
export const UPDATE_BASIC_TYPE_CATEGORY_SUCCESS =
  'UPDATE_BASIC_TYPE_CATEGORY_SUCCESS';
export const UPDATE_BASIC_TYPE_CATEGORY_ERROR =
  'UPDATE_BASIC_TYPE_CATEGORY_ERROR';

export const DELETE_BASIC_TYPE_CATEGORY = 'DELETE_BASIC_TYPE_CATEGORY';
export const DELETE_BASIC_TYPE_CATEGORY_SUCCESS =
  'DELETE_BASIC_TYPE_CATEGORY_SUCCESS';
export const DELETE_BASIC_TYPE_CATEGORY_ERROR =
  'DELETE_BASIC_TYPE_CATEGORY_ERROR';
