export const GET_ALL_COUNTRY = 'GET_ALL_COUNTRY';
export const GET_ALL_COUNTRY_SUCCESS = 'GET_ALL_COUNTRY_SUCCESS';
export const GET_ALL_COUNTRY_ERROR = 'GET_ALL_COUNTRY_ERROR';
export const GET_SINGLE_COUNTRY = 'GET_SINGLE_COUNTRY';
export const GET_SINGLE_COUNTRY_SUCCESS = 'GET_SINGLE_COUNTRY_SUCCESS';
export const GET_SINGLE_COUNTRY_ERROR = 'GET_SINGLE_COUNTRY_ERROR';
export const CREATE_COUNTRY = 'CREATE_COUNTRY';
export const CREATE_COUNTRY_SUCCESS = 'CREATE_COUNTRY_SUCCESS';
export const CREATE_COUNTRY_ERROR = 'CREATE_COUNTRY_ERROR';
export const UPDATE_COUNTRY = 'UPDATE_COUNTRY';
export const UPDATE_COUNTRY_SUCCESS = 'UPDATE_COUNTRY_SUCCESS';
export const UPDATE_COUNTRY_ERROR = 'UPDATE_COUNTRY_ERROR';
export const DELETE_COUNTRY = 'DELETE_COUNTRY';
export const DELETE_COUNTRY_SUCCESS = 'DELETE_COUNTRY_SUCCESS';
export const DELETE_COUNTRY_ERROR = 'DELETE_COUNTRY_ERROR';
