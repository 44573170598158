export const GET_ALL_TEAM_MEMBER_ROLE = 'GET_ALL_TEAM_MEMBER_ROLE';
export const GET_ALL_TEAM_MEMBER_ROLE_SUCCESS =
  'GET_ALL_TEAM_MEMBER_ROLE_SUCCESS';
export const GET_ALL_TEAM_MEMBER_ROLE_ERROR = 'GET_ALL_TEAM_MEMBER_ROLE_ERROR';
export const CREATE_TEAM_MEMBER_ROLE = 'CREATE_TEAM_MEMBER_ROLE';
export const CREATE_TEAM_MEMBER_ROLE_SUCCESS =
  'CREATE_TEAM_MEMBER_ROLE_SUCCESS';
export const CREATE_TEAM_MEMBER_ROLE_ERROR = 'CREATE_TEAM_MEMBER_ROLE_ERROR';
export const UPDATE_TEAM_MEMBER_ROLE = 'UPDATE_TEAM_MEMBER_ROLE';
export const UPDATE_TEAM_MEMBER_ROLE_SUCCESS =
  'UPDATE_TEAM_MEMBER_ROLE_SUCCESS';
export const UPDATE_TEAM_MEMBER_ROLE_ERROR = 'UPDATE_TEAM_MEMBER_ROLE_ERROR';
export const DELETE_TEAM_MEMBER_ROLE = 'DELETE_TEAM_MEMBER_ROLE';
export const DELETE_TEAM_MEMBER_ROLE_SUCCESS =
  'DELETE_TEAM_MEMBER_ROLE_SUCCESS';
export const DELETE_TEAM_MEMBER_ROLE_ERROR = 'DELETE_TEAM_MEMBER_ROLE_ERROR';
