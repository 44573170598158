export const GET_ALL_CONTEST_CATEGORY = 'GET_ALL_CONTEST_CATEGORY';
export const GET_ALL_CONTEST_CATEGORY_SUCCESS =
  'GET_ALL_CONTEST_CATEGORY_SUCCESS';
export const GET_ALL_CONTEST_CATEGORY_ERROR = 'GET_ALL_CONTEST_CATEGORY_ERROR';

export const CREATE_CONTEST_CATEGORY = 'CREATE_CONTEST_CATEGORY';
export const CREATE_CONTEST_CATEGORY_SUCCESS =
  'CREATE_CONTEST_CATEGORY_SUCCESS';
export const CREATE_CONTEST_CATEGORY_ERROR = 'CREATE_CONTEST_CATEGORY_ERROR';

export const UPDATE_CONTEST_CATEGORY = 'UPDATE_CONTEST_CATEGORY';
export const UPDATE_CONTEST_CATEGORY_SUCCESS =
  'UPDATE_CONTEST_CATEGORY_SUCCESS';
export const UPDATE_CONTEST_CATEGORY_ERROR = 'UPDATE_CONTEST_CATEGORY_ERROR';

export const DELETE_CONTEST_CATEGORY = 'DELETE_CONTEST_CATEGORY';
export const DELETE_CONTEST_CATEGORY_SUCCESS =
  'DELETE_CONTEST_CATEGORY_SUCCESS';
export const DELETE_CONTEST_CATEGORY_ERROR = 'DELETE_CONTEST_CATEGORY_ERROR';
