export const GET_ALL_TIMEZONE = 'GET_ALL_TIMEZONE';
export const GET_ALL_TIMEZONE_SUCCESS = 'GET_ALL_TIMEZONE_SUCCESS';
export const GET_ALL_TIMEZONE_ERROR = 'GET_ALL_TIMEZONE_ERROR';
export const GET_SINGLE_TIMEZONE = 'GET_SINGLE_TIMEZONE';
export const GET_SINGLE_TIMEZONE_SUCCESS = 'GET_SINGLE_TIMEZONE_SUCCESS';
export const GET_SINGLE_TIMEZONE_ERROR = 'GET_SINGLE_TIMEZONE_ERROR';
export const CREATE_TIMEZONE = 'CREATE_TIMEZONE';
export const CREATE_TIMEZONE_SUCCESS = 'CREATE_TIMEZONE_SUCCESS';
export const CREATE_TIMEZONE_ERROR = 'CREATE_TIMEZONE_ERROR';
export const UPDATE_TIMEZONE = 'UPDATE_TIMEZONE';
export const UPDATE_TIMEZONE_SUCCESS = 'UPDATE_TIMEZONE_SUCCESS';
export const UPDATE_TIMEZONE_ERROR = 'UPDATE_TIMEZONE_ERROR';
export const DELETE_TIMEZONE = 'DELETE_TIMEZONE';
export const DELETE_TIMEZONE_SUCCESS = 'DELETE_TIMEZONE_SUCCESS';
export const DELETE_TIMEZONE_ERROR = 'DELETE_TIMEZONE_ERROR';
