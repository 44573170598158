export const GET_ALL_MODULE_TYPE = 'GET_ALL_MODULE_TYPE';
export const GET_ALL_MODULE_TYPE_SUCCESS = 'GET_ALL_MODULE_TYPE_SUCCESS';
export const GET_ALL_MODULE_TYPE_ERROR = 'GET_ALL_MODULE_TYPE_ERROR';
export const CREATE_MODULE_TYPE = 'CREATE_MODULE_TYPE';
export const CREATE_MODULE_TYPE_SUCCESS = 'CREATE_MODULE_TYPE_SUCCESS';
export const CREATE_MODULE_TYPE_ERROR = 'CREATE_MODULE_TYPE_ERROR';
export const UPDATE_MODULE_TYPE = 'UPDATE_MODULE_TYPE';
export const UPDATE_MODULE_TYPE_SUCCESS = 'UPDATE_MODULE_TYPE_SUCCESS';
export const UPDATE_MODULE_TYPE_ERROR = 'UPDATE_MODULE_TYPE_ERROR';
export const DELETE_MODULE_TYPE = 'DELETE_MODULE_TYPE';
export const DELETE_MODULE_TYPE_SUCCESS = 'DELETE_MODULE_TYPE_SUCCESS';
export const DELETE_MODULE_TYPE_ERROR = 'DELETE_MODULE_TYPE_ERROR';
