export const GET_ALL_TOPIC = 'GET_ALL_TOPIC';
export const GET_ALL_TOPIC_SUCCESS = 'GET_ALL_TOPIC_SUCCESS';
export const GET_ALL_TOPIC_ERROR = 'GET_ALL_TOPIC_ERROR';
export const GET_SINGLE_TOPIC = 'GET_SINGLE_TOPIC';
export const GET_SINGLE_TOPIC_SUCCESS = 'GET_SINGLE_TOPIC_SUCCESS';
export const GET_SINGLE_TOPIC_ERROR = 'GET_SINGLE_TOPIC_ERROR';
export const CREATE_TOPIC = 'CREATE_TOPIC';
export const CREATE_TOPIC_SUCCESS = 'CREATE_TOPIC_SUCCESS';
export const CREATE_TOPIC_ERROR = 'CREATE_TOPIC_ERROR';
export const UPDATE_TOPIC = 'UPDATE_TOPIC';
export const UPDATE_TOPIC_SUCCESS = 'UPDATE_TOPIC_SUCCESS';
export const UPDATE_TOPIC_ERROR = 'UPDATE_TOPIC_ERROR';
export const DELETE_TOPIC = 'DELETE_TOPIC';
export const DELETE_TOPIC_SUCCESS = 'DELETE_TOPIC_SUCCESS';
export const DELETE_TOPIC_ERROR = 'DELETE_TOPIC_ERROR';
