export const GET_ALL_GOALS = 'GET_ALL_GOALS';
export const GET_ALL_GOALS_SUCCESS = 'GET_ALL_GOALS_SUCCESS';
export const GET_ALL_GOALS_ERROR = 'GET_ALL_GOAL_ERROR';
export const CREATE_GOAL = 'CREATE_GOAL';
export const CREATE_GOAL_SUCCESS = 'CREATE_GOAL_SUCCESS';
export const CREATE_GOAL_ERROR = 'CREATE_GOAL_ERROR';
export const UPDATE_GOAL = 'UPDATE_GOAL';
export const UPDATE_GOAL_SUCCESS = 'UPDATE_GOAL_SUCCESS';
export const UPDATE_GOAL_ERROR = 'UPDATE_GOAL_ERROR';
export const DELETE_GOAL = 'DELETE_GOAL';
export const DELETE_GOAL_SUCCESS = 'DELETE_GOAL_SUCCESS';
export const DELETE_GOAL_ERROR = 'DELETE_GOAL_ERROR';
