export const GET_ALL_PARTNER_TYPE = 'GET_ALL_PARTNER_TYPE';
export const GET_ALL_PARTNER_TYPE_SUCCESS = 'GET_ALL_PARTNER_TYPE_SUCCESS';
export const GET_ALL_PARTNER_TYPE_ERROR = 'GET_ALL_PARTNER_TYPE_ERROR';
export const CREATE_PARTNER_TYPE = 'CREATE_PARTNER_TYPE';
export const CREATE_PARTNER_TYPE_SUCCESS = 'CREATE_PARTNER_TYPE_SUCCESS';
export const CREATE_PARTNER_TYPE_ERROR = 'CREATE_PARTNER_TYPE_ERROR';
export const UPDATE_PARTNER_TYPE = 'UPDATE_PARTNER_TYPE';
export const UPDATE_PARTNER_TYPE_SUCCESS = 'UPDATE_PARTNER_TYPE_SUCCESS';
export const UPDATE_PARTNER_TYPE_ERROR = 'UPDATE_PARTNER_TYPE_ERROR';
export const DELETE_PARTNER_TYPE = 'DELETE_PARTNER_TYPE';
export const DELETE_PARTNER_TYPE_SUCCESS = 'DELETE_PARTNER_TYPE_SUCCESS';
export const DELETE_PARTNER_TYPE_ERROR = 'DELETE_PARTNER_TYPE_ERROR';
