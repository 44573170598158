export const GET_COURSE_CATEGORYS = 'GET_COURSE_CATEGORYS';
export const GET_COURSE_CATEGORYS_SUCCESS = 'GET_COURSE_CATEGORYS_SUCCESS';
export const GET_COURSE_CATEGORYS_ERROR = 'GET_COURSE_CATEGORYS_ERROR';

export const CREATE_COURSE_CATEGORY = 'CREATE_COURSE_CATEGORY';
export const CREATE_COURSE_CATEGORY_SUCCESS = 'CREATE_COURSE_CATEGORY_SUCCESS';
export const CREATE_COURSE_CATEGORY_ERROR = 'CREATE_COURSE_CATEGORY_ERROR';

export const DELETE_COURSE_CATEGORY = 'DELETE_COURSE_CATEGORY';
export const DELETE_COURSE_CATEGORY_SUCCESS = 'DELETE_COURSE_CATEGORY_SUCCESS';
export const DELETE_COURSE_CATEGORY_ERROR = 'DELETE_COURSE_CATEGORY_ERROR';

export const UPDATE_COURSE_CATEGORY = 'UPDATE_COURSE_CATEGORY';
export const UPDATE_COURSE_CATEGORY_SUCCESS = 'UPDATE_COURSE_CATEGORY_SUCCESS';
export const UPDATE_COURSE_CATEGORY_ERROR = 'UPDATE_COURSE_CATEGORY_ERROR';