export const GET_ALL_BASIC_TYPE = 'GET_ALL_BASIC_TYPE';
export const GET_ALL_BASIC_TYPE_SUCCESS = 'GET_ALL_BASIC_TYPE_SUCCESS';
export const GET_ALL_BASIC_TYPE_ERROR = 'GET_ALL_BASIC_TYPE_ERROR';

export const GET_PARENT_BASIC_TYPE = 'GET_PARENT_BASIC_TYPE';
export const GET_PARENT_BASIC_TYPE_SUCCESS = 'GET_PARENT_BASIC_TYPE_SUCCESS';
export const GET_PARENT_BASIC_TYPE_ERROR = 'GET_PARENT_BASIC_TYPE_ERROR';

export const CREATE_BASIC_TYPE = 'CREATE_BASIC_TYPE';
export const CREATE_BASIC_TYPE_SUCCESS = 'CREATE_BASIC_TYPE_SUCCESS';
export const CREATE_BASIC_TYPE_ERROR = 'CREATE_BASIC_TYPE_ERROR';

export const UPDATE_BASIC_TYPE = 'UPDATE_BASIC_TYPE';
export const UPDATE_BASIC_TYPE_SUCCESS = 'UPDATE_BASIC_TYPE_SUCCESS';
export const UPDATE_BASIC_TYPE_ERROR = 'UPDATE_BASIC_TYPE_ERROR';

export const DELETE_BASIC_TYPE = 'DELETE_BASIC_TYPE';
export const DELETE_BASIC_TYPE_SUCCESS = 'DELETE_BASIC_TYPE_SUCCESS';
export const DELETE_BASIC_TYPE_ERROR = 'DELETE_BASIC_TYPE_ERROR';

export const ORDER_BASIC_TYPE = 'ORDER_BASIC_TYPE';
export const ORDER_BASIC_TYPE_SUCCESS = 'ORDER_BASIC_TYPE_SUCCESS';
export const ORDER_BASIC_TYPE_ERROR = 'ORDER_BASIC_TYPE_ERROR';
