export const GET_ALL_TEAM = 'GET_ALL_TEAM';
export const GET_ALL_TEAM_SUCCESS = 'GET_ALL_TEAM_SUCCESS';
export const GET_ALL_TEAM_ERROR = 'GET_ALL_TEAM_ERROR';
export const CREATE_TEAM = 'CREATE_TEAM';
export const CREATE_TEAM_SUCCESS = 'CREATE_TEAM_SUCCESS';
export const CREATE_TEAM_ERROR = 'CREATE_TEAM_ERROR';
export const UPDATE_TEAM = 'UPDATE_TEAM';
export const UPDATE_TEAM_SUCCESS = 'UPDATE_TEAM_SUCCESS';
export const UPDATE_TEAM_ERROR = 'UPDATE_TEAM_ERROR';
export const DELETE_TEAM = 'DELETE_TEAM';
export const DELETE_TEAM_SUCCESS = 'DELETE_TEAM_SUCCESS';
export const DELETE_TEAM_ERROR = 'DELETE_TEAM_ERROR';
