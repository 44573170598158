export const GET_ALL_EXPERTISE_CATEGORY = 'GET_ALL_EXPERTISE_CATEGORY';
export const GET_ALL_EXPERTISE_CATEGORY_SUCCESS =
  'GET_ALL_EXPERTISE_CATEGORY_SUCCESS';
export const GET_ALL_EXPERTISE_CATEGORY_ERROR =
  'GET_ALL_EXPERTISE_CATEGORY_ERROR';

export const GET_ALL_SKILL = 'GET_ALL_SKILL';
export const GET_ALL_SKILL_SUCCESS = 'GET_ALL_SKILL_SUCCESS';
export const GET_ALL_SKILL_ERROR = 'GET_ALL_SKILL_ERROR';

export const CREATE_EXPERTISE_CATEGORY = 'CREATE_EXPERTISE_CATEGORY';
export const CREATE_EXPERTISE_CATEGORY_SUCCESS =
  'CREATE_EXPERTISE_CATEGORY_SUCCESS';
export const CREATE_EXPERTISE_CATEGORY_ERROR =
  'CREATE_EXPERTISE_CATEGORY_ERROR';

export const UPDATE_EXPERTISE_CATEGORY = 'UPDATE_EXPERTISE_CATEGORY';
export const UPDATE_EXPERTISE_CATEGORY_SUCCESS =
  'UPDATE_EXPERTISE_CATEGORY_SUCCESS';
export const UPDATE_EXPERTISE_CATEGORY_ERROR =
  'UPDATE_EXPERTISE_CATEGORY_ERROR';

export const DELETE_EXPERTISE_CATEGORY = 'DELETE_EXPERTISE_CATEGORY';
export const DELETE_EXPERTISE_CATEGORY_SUCCESS =
  'DELETE_EXPERTISE_CATEGORY_SUCCESS';
export const DELETE_EXPERTISE_CATEGORY_ERROR =
  'DELETE_EXPERTISE_CATEGORY_ERROR';

export const ORDER_EXPERTISE_CATEGORY = 'ORDER_EXPERTISE_CATEGORY';
export const ORDER_EXPERTISE_CATEGORY_SUCCESS =
  'ORDER_EXPERTISE_CATEGORY_SUCCESS';
export const ORDER_EXPERTISE_CATEGORY_ERROR = 'ORDER_EXPERTISE_CATEGORY_ERROR';
