export const GET_ALL_COMMUNITY_ROLE = 'GET_ALL_COMMUNITY_ROLE';
export const GET_ALL_COMMUNITY_ROLE_SUCCESS = 'GET_ALL_COMMUNITY_ROLE_SUCCESS';
export const GET_ALL_COMMUNITY_ROLE_ERROR = 'GET_ALL_COMMUNITY_ROLE_ERROR';
export const GET_COMMUNITY_LIST_BY_ROLE = 'GET_COMMUNITY_LIST_BY_ROLE';
export const GET_COMMUNITY_LIST_BY_ROLE_SUCCESS =
  'GET_COMMUNITY_LIST_BY_ROLE_SUCCESS';
export const GET_COMMUNITY_LIST_BY_ROLE_ERROR =
  'GET_COMMUNITY_LIST_BY_ROLE_ERROR';
export const GET_ALL_COMMUNITY = 'GET_ALL_COMMUNITY';
export const GET_ALL_COMMUNITY_SUCCESS = 'GET_ALL_COMMUNITY_SUCCESS';
export const GET_ALL_COMMUNITY_ERROR = 'GET_ALL_COMMUNITY_ERROR';
export const GET_SINGLE_COMMUNITY = 'GET_SINGLE_COMMUNITY';
export const GET_SINGLE_COMMUNITY_SUCCESS = 'GET_SINGLE_COMMUNITY_SUCCESS';
export const GET_SINGLE_COMMUNITY_ERROR = 'GET_SINGLE_COMMUNITY_ERROR';
export const CREATE_COMMUNITY = 'CREATE_COMMUNITY';
export const CREATE_COMMUNITY_SUCCESS = 'CREATE_COMMUNITY_SUCCESS';
export const CREATE_COMMUNITY_ERROR = 'CREATE_COMMUNITY_ERROR';
export const UPDATE_COMMUNITY = 'UPDATE_COMMUNITY';
export const UPDATE_COMMUNITY_SUCCESS = 'UPDATE_COMMUNITY_SUCCESS';
export const UPDATE_COMMUNITY_ERROR = 'UPDATE_COMMUNITY_ERROR';
export const DELETE_COMMUNITY = 'DELETE_COMMUNITY';
export const DELETE_COMMUNITY_SUCCESS = 'DELETE_COMMUNITY_SUCCESS';
export const DELETE_COMMUNITY_ERROR = 'DELETE_COMMUNITY_ERROR';
