export const GET_ALL_LANGUAGE_LEVEL = 'GET_ALL_LANGUAGE_LEVEL';
export const GET_ALL_LANGUAGE_LEVEL_SUCCESS = 'GET_ALL_LANGUAGE_LEVEL_SUCCESS';
export const GET_ALL_LANGUAGE_LEVEL_ERROR = 'GET_ALL_LANGUAGE_LEVEL_ERROR';
export const GET_SINGLE_LANGUAGE_LEVEL = 'GET_SINGLE_LANGUAGE_LEVEL';
export const GET_SINGLE_LANGUAGE_LEVEL_SUCCESS =
  'GET_SINGLE_LANGUAGE_LEVEL_SUCCESS';
export const GET_SINGLE_LANGUAGE_LEVEL_ERROR =
  'GET_SINGLE_LANGUAGE_LEVEL_ERROR';
export const CREATE_LANGUAGE_LEVEL = 'CREATE_LANGUAGE_LEVEL';
export const CREATE_LANGUAGE_LEVEL_SUCCESS = 'CREATE_LANGUAGE_LEVEL_SUCCESS';
export const CREATE_LANGUAGE_LEVEL_ERROR = 'CREATE_LANGUAGE_LEVEL_ERROR';
export const UPDATE_LANGUAGE_LEVEL = 'UPDATE_LANGUAGE_LEVEL';
export const UPDATE_LANGUAGE_LEVEL_SUCCESS = 'UPDATE_LANGUAGE_LEVEL_SUCCESS';
export const UPDATE_LANGUAGE_LEVEL_ERROR = 'UPDATE_LANGUAGE_LEVEL_ERROR';
export const DELETE_LANGUAGE_LEVEL = 'DELETE_LANGUAGE_LEVEL';
export const DELETE_LANGUAGE_LEVEL_SUCCESS = 'DELETE_LANGUAGE_LEVEL_SUCCESS';
export const DELETE_LANGUAGE_LEVEL_ERROR = 'DELETE_LANGUAGE_LEVEL_ERROR';
