export const GET_ALL_HUBBERS_TEAM = 'GET_ALL_HUBBERS_TEAM';
export const GET_ALL_HUBBERS_TEAM_SUCCESS = 'GET_ALL_HUBBERS_TEAM_SUCCESS';
export const GET_ALL_HUBBERS_TEAM_ERROR = 'GET_ALL_HUBBERS_TEAM_ERROR';
export const CREATE_HUBBERS_TEAM = 'CREATE_HUBBERS_TEAM';
export const CREATE_HUBBERS_TEAM_SUCCESS = 'CREATE_HUBBERS_TEAM_SUCCESS';
export const CREATE_HUBBERS_TEAM_ERROR = 'CREATE_HUBBERS_TEAM_ERROR';
export const UPDATE_HUBBERS_TEAM = 'UPDATE_HUBBERS_TEAM';
export const UPDATE_HUBBERS_TEAM_SUCCESS = 'UPDATE_HUBBERS_TEAM_SUCCESS';
export const UPDATE_HUBBERS_TEAM_ERROR = 'UPDATE_HUBBERS_TEAM_ERROR';
export const DELETE_HUBBERS_TEAM = 'DELETE_HUBBERS_TEAM';
export const DELETE_HUBBERS_TEAM_SUCCESS = 'DELETE_HUBBERS_TEAM_SUCCESS';
export const DELETE_HUBBERS_TEAM_ERROR = 'DELETE_HUBBERS_TEAM_ERROR';
export const ORDER_HUBBERS_TEAM = 'ORDER_HUBBERS_TEAM';
export const ORDER_HUBBERS_TEAM_SUCCESS = 'ORDER_HUBBERS_TEAM_SUCCESS';
export const ORDER_HUBBERS_TEAM_ERROR = 'ORDER_HUBBERS_TEAM_ERROR';
