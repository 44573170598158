export const GET_ALL_GROUP = 'GET_ALL_GROUP';
export const GET_ALL_GROUP_SUCCESS = 'GET_ALL_GROUP_SUCCESS';
export const GET_ALL_GROUP_ERROR = 'GET_ALL_GROUP_ERROR';
export const GET_SINGLE_GROUP = 'GET_SINGLE_GROUP';
export const GET_SINGLE_GROUP_SUCCESS = 'GET_SINGLE_GROUP_SUCCESS';
export const GET_SINGLE_GROUP_ERROR = 'GET_SINGLE_GROUP_ERROR';
export const CREATE_GROUP = 'CREATE_GROUP';
export const CREATE_GROUP_SUCCESS = 'CREATE_GROUP_SUCCESS';
export const CREATE_GROUP_ERROR = 'CREATE_GROUP_ERROR';
export const UPDATE_GROUP = 'UPDATE_GROUP';
export const UPDATE_GROUP_SUCCESS = 'UPDATE_GROUP_SUCCESS';
export const UPDATE_GROUP_ERROR = 'UPDATE_GROUP_ERROR';
export const DELETE_GROUP = 'DELETE_GROUP';
export const DELETE_GROUP_SUCCESS = 'DELETE_GROUP_SUCCESS';
export const DELETE_GROUP_ERROR = 'DELETE_GROUP_ERROR';
